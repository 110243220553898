import React from 'react';
import { Badge, Button, Card, Col, Row } from 'react-bootstrap';
import { Trash } from 'react-bootstrap-icons';
import _ from 'underscore';

import { useAccount, useBasket } from '@lib/hooks';

import HardwareConfigurationItem from './hardware-configuration-item';


function HardwareItems() {
    const basket = useBasket();
    const { fixedAttrs: { hardware }, model: account } = useAccount();
    const hardwareItems = _.groupBy(hardware, (item) => item.product.uid);

    // If a resi customer is buying a phone, we assume it's being configured to the master
    // account and do not need to present the configuration option to the customer.
    const hideItemsWithConfig = account.isResidential && account.configurableHardwareCount === 1;

    return (
        <React.Fragment>
            {Object.keys(hardwareItems).map((key) => {
                const itemsWithConfig = hardwareItems[key].filter((item) => item.configuration);
                const quantity = hardwareItems[key].reduce((sum, item) => sum + item.quantity, 0);
                const item = hardwareItems[key][0];

                return (
                    <Card key={item.product.uid} bg="light" className="mt-4 mb-4">
                        <Card.Body>
                            <Row>
                                <Col xs={10}>
                                    <strong>{item.product.name}</strong>
                                    <Badge bg="secondary" className="ms-2">
                                        {quantity}
                                    </Badge>
                                    <div>
                                        <img
                                            src={item.product.imageUrl}
                                            alt={item.product.description}
                                            height="100"
                                            className="mt-2 p-2 bg-white shadow rounded"
                                            onError={(e) => e.target.style.display = 'none'}
                                        />
                                    </div>
                                </Col>
                                <Col className="text-end">
                                    <Button
                                        size="sm"
                                        variant="link text-body"
                                        onClick={() => basket.remove(item.product)}
                                    >
                                        <Trash />
                                    </Button>
                                </Col>
                            </Row>

                            {!hideItemsWithConfig && itemsWithConfig.length > 0 && <hr />}

                            {!hideItemsWithConfig && itemsWithConfig.map((model, index) => (
                                <HardwareConfigurationItem
                                    key={model.cid}
                                    basketModel={model}
                                    itemNum={index + 1}
                                />
                            ))}
                        </Card.Body>
                    </Card>
                );
            })}
        </React.Fragment>
    );
}


export default HardwareItems;
