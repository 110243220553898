import dayjs from 'dayjs';
import _ from 'underscore';
import _s from 'underscore.string';

import AddressModel from '@content/contact-page/address-model';
import AdminDetailsModel from '@content/contact-page/admin-details-model';
import api from '@lib/api';
import { BasketProduct } from '@lib/basket';
import constants from '@lib/constants';
import fmt from '@lib/formatters';
import { BaseModel } from '@lib/models';
import validators from '@lib/validators';

import { PACKAGED_NUMBER_REFUND_ID } from './price-model';

const CUSTOMER_TYPE_BUSINESS = 'business';
const CUSTOMER_TYPE_RESIDENTIAL = 'residential';

const PACKAGED_SERVICES = 'packagedServices';


class OnboardingAccountModel extends BaseModel {
    static defaults = {
        customerType: '',
        email: '',
        voipfone: '',
        memorablePhrase: '',
        memorablePhraseHint: '',
        title: '',
        firstname: '',
        initials: '',
        surname: '',
        phone: '',
        accountAddress: new AddressModel(),
        accountPremises: '',
        accountThoroughfare: '',
        billingAddress: new AddressModel(),
        invoiceAddress: new AddressModel(),
        deliveryAddress: new AddressModel(),
        deliveryAcceptTerms: false,
        deliveryNotificationEmail: '',
        deliveryNotificationMobile: '',
        emailSubscribe: false,
        acceptTerms: false,
        _plan: null,
        _planSeats: 0,
        plan: null,
        planSeats: 0,
        portNumbers: [],
        _buyNumbers: null,
        buyNumbers: [],
        _hardware: null,
        hardware: [],
        _broadband: null,
        broadband: [],
        fees: [],
        hasTrial: false,
        technicalPartnerCode: null,
        dateCreated: null,
        dateLastUpdated: null,
        hasAdmin: false,
        adminDetails: null,
    }

    validation = {
        memorablePhrase: [
            {
                required: true,
                msg: 'Word is required',
            },
            {
                minLength: 5,
                msg: 'Word must be at least 5 characters',
            },
            {
                maxLength: 20,
                msg: 'Word must be at most 20 characters',
            },
            {
                pattern: validators.patterns.alphanumeric,
                msg: 'Word can only contain numbers and letters',
            },
        ],
        memorablePhraseHint: [
            {
                required: false,
                fn: (val) => {
                    const memorablePhrase = this.get('memorablePhrase').toLowerCase();
                    if (val.length > 128) {
                        return 'Word must be at most 128 characters';
                    } else if (memorablePhrase.length > 0 && val.toLowerCase().indexOf(memorablePhrase) > -1) {
                        return 'Hint cannot contain your memorable word';
                    }
                },
            },
        ],
        title: {
            required: () => this.isResidential,
        },
        firstname: {
            required: true,
            msg: 'First name is required',
        },
        initials: {
            required: false,
            fn: validators.asciiLetters,
            msg: 'Please only use the letters A-Z',
            maxLength: 20,
        },
        surname: {
            required: true,
            msg: 'Last name is required',
        },
        phone: {
            required: false,
            fn: validators.phoneNumber,
            msg: 'Please enter a valid phone number',
        },
        accountPremises: {
            required: () => this.isResidential,
        },
        accountThoroughfare: {
            required: () => this.isResidential,
        },
        accountAddress: {
            fn: (val) => {
                if (!val.isValid()) {
                    return 'Account address is required';
                }
            },
        },
        billingAddress: {
            fn: (val) => {
                const isValid = val.get('useAccountAddress') ? this.get('accountAddress').isValid() : val.isValid();
                if (!isValid) {
                    return 'Billing address is required';
                }
            },
        },
        invoiceAddress: {
            fn: (val) => {
                const isValid = val.get('useAccountAddress') ? this.get('accountAddress').isValid() : val.isValid();
                if (!isValid) {
                    return 'Invoice address is required';
                }
            },
        },
        deliveryAddress: {
            fn: (val) => {
                const isValid = val.get('useAccountAddress') ? this.get('accountAddress').isValid() : val.isValid();
                if (!isValid) {
                    return 'Delivery address is required';
                }
            },
        },
        deliveryAcceptTerms: {
            fn: (val) => {
                if (this.hasHardware && !val) {
                    return 'Please agree to the hardware terms';
                }
            },
        },
        deliveryNotificationEmail: {
            required: () => this.hasHardware,
            pattern: 'email',
            msg: 'Delivery notification email is required',
        },
        deliveryNotificationMobile: {
            required: () => this.hasHardware,
            fn: validators.phoneNumber,
            msg: 'Delivery notification mobile number is required',
        },
        adminDetails: {
            required: () => this.get('hasAdmin'),
            fn: (val) => {
                if (this.get('hasAdmin') && !val.isValid()) {
                    return 'Trusted helper contact details are required';
                }
            },
        },
        acceptTerms: {
            acceptance: true,
            msg: 'Please agree to the terms of service',
        },
    }

    productToAccount = {
        plan: 'plan',
        number: 'buyNumbers',
        hardware: 'hardware',
        broadband: 'broadband',
        fee: 'fees',
    }

    url = () => `onboarding/accounts/${this.context?.account}`

    static parse(data) {
        const email = String(data.email);
        const accountAddress = new AddressModel(data.account_address, { parse: true });
        const billingAddress = new AddressModel(data.billing_address, { parse: true });
        const invoiceAddress = new AddressModel(data.invoice_address, { parse: true });
        const deliveryAddress = new AddressModel(data.delivery_address, { parse: true });
        const adminDetails = new AdminDetailsModel(data.admin_details, { parse: true });

        adminDetails.set('accountEmail', email);
        billingAddress.set('useAccountAddress', !billingAddress.isValid() || _.isEqual(billingAddress.attributes, accountAddress.attributes));
        invoiceAddress.set('useAccountAddress', !invoiceAddress.isValid() || _.isEqual(invoiceAddress.attributes, accountAddress.attributes));
        deliveryAddress.set('useAccountAddress', !deliveryAddress.isValid() || _.isEqual(deliveryAddress.attributes, accountAddress.attributes));
        accountAddress.set({
            premises: data.account_premises !== null ? String(data.account_premises) : '',
            thoroughfare: data.account_thoroughfare !== null ? String(data.account_thoroughfare) : '',
        });

        return {
            email,
            voipfone: String(data.voipfone),
            memorablePhrase: data.memorable_phrase || '',
            memorablePhraseHint: data.memorable_phrase_hint || '',
            title: data.title !== null ? String(data.title) : '',
            firstname: data.firstname !== null ? String(data.firstname) : '',
            initials: data.initials !== null ? String(data.initials) : '',
            surname: data.surname !== null ? String(data.surname) : '',
            phone: data.phone || '',
            accountAddress,
            billingAddress,
            invoiceAddress,
            deliveryAddress,
            hasAdmin: _.some(_.values(data.admin_details)),
            adminDetails,
            portNumbers: data.port_numbers || [],
            emailSubscribe: Boolean(data.email_subscribe),
            acceptTerms: Boolean(data.accept_terms),
            deliveryAcceptTerms: Boolean(data.delivery_accept_terms),
            deliveryNotificationEmail: data.delivery_notification_email || email,
            deliveryNotificationMobile: data.delivery_notification_mobile || '',
            _plan: data.plan_id,
            _planSeats: parseInt(data.plan_seats),
            _buyNumbers: data.buy_numbers,
            _hardware: data.hardware,
            _broadband: data.broadband,
            hasTrial: Boolean(data.has_trial),
            technicalPartnerCode: data.technical_partner_code,
            dateCreated: dayjs(data.date_created),
            dateLastUpdated: data.date_last_updated ? dayjs(data.date_last_updated) : null,
        };
    }

    read() {
        api.v1.get(this.url(), 'json')
            .then((resp) => {
                this.set(OnboardingAccountModel.parse(resp));
                this.get('accountAddress').trigger('sync');
                this.get('billingAddress').trigger('sync');
                this.get('invoiceAddress').trigger('sync');
                this.get('deliveryAddress').trigger('sync');
                this.trigger('sync');
            })
            .catch((resp) => console.log(`Error: ${resp}`));
    }

    save(params) {
        const data = !_.isUndefined(params) ? params : this.parameters;
        api.v1.put(`${this.url()}/update`, data)
            .then(() => {
                this.get('accountAddress').trigger('sync');
                this.get('billingAddress').trigger('sync');
                this.get('invoiceAddress').trigger('sync');
                this.get('deliveryAddress').trigger('sync');
                this.trigger('sync');
            })
            .catch(e => this.trigger('error', e.error));
    }

    saveValid() {
        this.save(this.validParameters);
    }

    getBasketItems() {
        return _.compact([
            this.get('plan'),
            ...this.get('buyNumbers'),
            ...this.get('hardware'),
            ...this.get('broadband'),
            ...this.get('fees'),
        ]);
    }

    getExtensions() {
        if (this.canRouteToMaster) {
            // While the master account isn't technically an extension, it's still returned
            // so there's a routing option when configuring hardware with a residential plan
            // or a 0-seat Flex plan (which is the only situation where this function is used).
            return [this.get('voipfone')];
        } else {
            return _.range(200, 200 + (this.get('plan') ? this.get('plan').quantity : 0)).map(item => String(item));
        }
    }

    getProducts(product) {
        return this.getBasketItems().filter(item => product.isEqual(item.product));
    }

    getRelatedProducts(product) {
        return this.getBasketItems().filter(item => product.isRelated(item.product));
    }

    get parameters() {
        const { plan, adminDetails } = this.attributes;
        const [, planSku] = plan?.product.sku.split('-') || '';
        const hasAdmin = this.get('hasAdmin') && this.isResidential;

        return {
            memorable_phrase: this.get('memorablePhrase'),
            memorable_phrase_hint: this.get('memorablePhraseHint'),
            title: this.get('title'),
            firstname: this.get('firstname'),
            initials: this.get('initials'),
            surname: this.get('surname'),
            phone: fmt.phoneNumberE164(this.get('phone')),
            ...this.addresses,
            account_premises: this.get('accountPremises'),
            account_thoroughfare: this.get('accountThoroughfare'),
            delivery_accept_terms: this.get('deliveryAcceptTerms'),
            delivery_notification_email: this.get('deliveryNotificationEmail'),
            delivery_notification_mobile: fmt.phoneNumberE164(this.get('deliveryNotificationMobile')),
            email_subscribe: this.get('emailSubscribe'),
            accept_terms: this.get('acceptTerms'),
            plan: plan ? planSku || plan.product.sku : '',
            plan_seats: plan?.quantity || 0,
            port_numbers: this.get('portNumbers').map(fmt.phoneNumberE164),
            buy_numbers: this.get('buyNumbers').map(item => item.product.uid),
            hardware: this.hardwareParameters,
            broadband: this.broadbandConfigParameters,
            has_admin: hasAdmin,
            ...(hasAdmin && {
                admin_details: adminDetails.parameters,
            }),
        };
    }

    get validParameters() {
        const parameters = this.parameters;
        const invalidFields = _.keys(this.validate() || {});
        const validFields = _.difference(_.keys(OnboardingAccountModel.defaults), invalidFields);
        return validFields.reduce((attributes, field) => {
            field = _s.underscored(field);

            if (_.has(parameters, field)) {
                attributes[field] = parameters[field];
            }

            return attributes;
        }, {});
    }

    get addresses() {
        const { accountAddress, billingAddress, invoiceAddress, deliveryAddress } = this.attributes;
        return {
            account_address: accountAddress.parameters,
            billing_address: billingAddress.get('useAccountAddress') ? accountAddress.parameters : billingAddress.parameters,
            invoice_address: invoiceAddress.get('useAccountAddress') ? accountAddress.parameters : invoiceAddress.parameters,
            ...(this.hasHardware && {
                delivery_address: deliveryAddress.get('useAccountAddress') ? accountAddress.parameters : deliveryAddress.parameters,
            }),
        };
    }

    get basketParameters() {
        return this.getBasketItems().reduce((obj, item) => {
            const uid = item.product.uid;
            return { ...obj, [uid]: (obj[uid] ? obj[uid] : 0) + item.quantity };
        }, {});
    }

    get hardwareParameters() {
        return this.get('hardware').reduce((obj, item) => {
            const uid = item.product.uid;
            return { ...obj, [uid]: (obj[uid] ? obj[uid] : 0) + item.quantity };
        }, {});
    }

    get hardwareConfigParameters() {
        return _.compact(this.get('hardware')
            .map(item => item.configuration?.parameters))
            .join('&');
    }

    get broadbandConfigParameters() {
        return this.get('broadband').reduce((obj, item) => ({
            ...obj,
            [item.product.number]: {
                ...item.configuration.parameters,
                skus: this.getRelatedProducts(item.product).map(related => related.product.uid),
            },
        }), {});
    }

    get basketCount() {
        return this.getBasketItems().reduce((sum, item) => sum + item.quantity, 0);
    }

    get hardwareCount() {
        return this.get('hardware').reduce((sum, item) => sum + item.quantity, 0);
    }

    get configurableHardwareCount() {
        return this.get('hardware').filter(item => item.configuration).reduce((sum, item) => sum + item.quantity, 0);
    }

    get hasHardware() {
        return this.hardwareCount > 0;
    }

    get hasGeoNumber() {
        return this.get('buyNumbers').some(number => number.product.isGeoNumber);
    }

    get hasPackagedService() {
        return this.getBasketItems().some(item => item.product.sku.startsWith(PACKAGED_SERVICES));
    }

    get hasPackagedNumberRefund() {
        return this.getBasketItems().some(item => item.product.sku === PACKAGED_NUMBER_REFUND_ID);
    }

    get hasBusinessPlan() {
        return this.get('plan') && !this.hasResidentialPlan;
    }

    get hasResidentialPlan() {
        return this.get('plan')?.product.isResidential;
    }

    get hasAllowedNumbers() {
        // Residential users are only permitted to buy (or port) a single UK local number
        const hasValidAmount = this.get('buyNumbers').length <= 1;
        const hasGeoOnly = this.get('buyNumbers').every(number => number.product.isGeoNumber);
        return this.isBusiness || (hasValidAmount && hasGeoOnly);
    }

    get hasAnyNumber() {
        return this.get('buyNumbers').length > 0 || this.get('portNumbers').length > 0;
    }

    get needsHardwareWarning() {
        return this.isResidential && this.configurableHardwareCount > 1;
    }

    get isResidential() {
        return this.get('customerType') === CUSTOMER_TYPE_RESIDENTIAL;
    }

    get isBusiness() {
        return this.get('customerType') === CUSTOMER_TYPE_BUSINESS;
    }

    get isBasketResidentialCompatible() {
        const buy = this.get('buyNumbers');
        const port = this.get('portNumbers');
        const hasValidGeo = buy.length === 1 && this.hasGeoNumber && port.length === 0;
        const hasValidPort = port.length === 1 && buy.length === 0;
        const hasNothing = buy.length === 0 && port.length === 0;
        return hasValidGeo || hasValidPort || hasNothing;
    }

    get canRouteToMaster() {
        return this.isResidential || this.get('plan')?.quantity === 0;
    }

    toggleCustomerType() {
        if (this.isResidential) {
            this.set('customerType', CUSTOMER_TYPE_BUSINESS);
        } else if (this.isBusiness) {
            this.set('customerType', CUSTOMER_TYPE_RESIDENTIAL);
        }

        if (this.get('plan')) {
            this.removeProduct(this.get('plan').product);
        }
    }

    contains(product) {
        return this.getProducts(product).length > 0;
    }

    _addProduct(product, quantity=1) {
        const attr = this.productToAccount[product.type];
        const value = this.get(attr);
        const newProduct = new BasketProduct({
            product,
            quantity,
            configuration: product.configurationCls ? new product.configurationCls() : null,
        });
        this.set(attr, _.isArray(value) ? [...value, newProduct] : newProduct);
        newProduct.on('change', () => this.trigger(`change:${attr} change`), this);
    }

    addProduct(product, quantity=1) {
        if (!product) return;
        const products = this.getProducts(product);

        if (product.configurationCls) {
            _.range(quantity).forEach(() => this._addProduct(product));
        } else if (products.length === 1) {
            products[0].quantity = products[0].quantity + quantity;
        } else {
            this._addProduct(product, quantity);
        }
    }

    updateProduct(product, quantity) {
        if (!product) return;
        const products = this.getProducts(product);
        const totalQuantity = products.reduce((sum, item) => sum + item.quantity, 0);

        if (quantity !== totalQuantity) {
            this.removeProduct(product);
            this.addProduct(product, quantity);
        }
    }

    removeProduct(product) {
        if (!product) return;
        const attr = this.productToAccount[product.type];
        const products = this.getProducts(product);
        const value = this.get(attr);

        if (products.length === 0) {
            return;
        }

        products.forEach(item => item.off('change', null, this));
        this.set(attr, _.isArray(value) ? _.without(value, ...products) : OnboardingAccountModel.defaults[attr]);
    }

    setNumber(product) {
        const isPortNumber = product && _.isString(product);
        let buyNumbers = this.get('buyNumbers');
        let portNumbers = this.get('portNumbers');

        if (product && isPortNumber) {
            portNumbers = [product];
        } else if (product) {
            buyNumbers = buyNumbers.filter(item => !item.product.isEqual(product));
            portNumbers = [];
        }

        buyNumbers.forEach(item => this.removeProduct(item.product));
        this.set('portNumbers', portNumbers);
    }

    getTotal(key) {
        return this.getBasketItems().reduce((sum, { attributes: { product, quantity }}) => {
            return sum + (product[key] * quantity);
        }, 0);
    }

    getTotalIncVat(key) {
        return this.getBasketItems().reduce((sum, { attributes: { product, quantity }}) => {
            const amount = product[key] * quantity;
            return sum + (product.hasVat ? amount * constants.VAT_RATE : amount);
        }, 0);
    }

    get totalExVat() {
        return this.getTotal('payTodayPrice');
    }

    get totalIncVat() {
        return this.getTotalIncVat('payTodayPrice');
    }

    get totalOneOffExVat() {
        return this.getTotal('oneOffPrice');
    }

    get totalOneOffIncVat() {
        return this.getTotalIncVat('oneOffPrice');
    }

    get totalRenewalPriceExVat() {
        return this.getTotal('renewalPrice');
    }

    get totalRenewalPriceIncVat() {
        return this.getTotalIncVat('renewalPrice');
    }
}


export default OnboardingAccountModel;

export {
    CUSTOMER_TYPE_BUSINESS,
    CUSTOMER_TYPE_RESIDENTIAL,
};
